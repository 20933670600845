//
//
// demo.js
//
// an initializer for demo management
//

import jQuery from 'jquery';
import RTCCon from './rtccon';
import VideoOverlay from './videoOverlay';

const exDemo = (($) => {
  /**
   * Check for jquery.qrcode dependency
   * jquery.qrcode - https://jeromeetienne.github.io/jquery-qrcode/
   */
  if (typeof $.fn.qrcode !== 'function') {
    throw new Error('demo requires jquery.qrcode.js (https://jeromeetienne.github.io/jquery-qrcode/)');
  }

  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'exDemo';
  const VERSION = '1.1.0';
  const DATA_KEY = 'ex.demo';
  const EVENT_KEY = `.${DATA_KEY}`;
  const DATA_API_KEY = '.data-api';
  const JQUERY_NO_CONFLICT = $.fn[NAME];

  const Event = {
    LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
  };

  const Default = {
    ID: null,
    TXT_START: 'Start demo',
    TXT_STOP: 'Stop demo',
    TXT_LOADING: 'Loading demo',
  };

  const Selector = {
    START: '[data-demo-start]',
    STOP: '[data-demo-stop]',
    VIDEO: '[data-demo-control="video"]',
    SCREEN: '[data-demo-control="screen"]',
    QRCODE: '[data-demo-qrcode]',
    START_ATTR: 'data-demo-start',
    TXT_START_ATTR: 'data-start-text',
    TXT_STOP_ATTR: 'data-stop-text',
    TXT_LOADING_ATTR: 'data-loading-text',
    CONTROLS: '[data-demo-controls]',
    VIDEO_CONTAINER: '[data-demo-video-container]',
    POPUP: '[data-demo-popup]',
  };

  const Text = {
    VIDEO_START: 'Share video',
    VIDEO_STOP: 'Stop video sharing',
    VIDEO_DENIED: 'Video access denied',
    SCREEN_START: 'Share screen',
    SCREEN_STOP: 'Stop screen sharing',
    SCREEN_DENIED: 'Screen access denied',
  };

  const StyleClass = {
    START: 'btn-primary',
    LOADING: 'btn-outline-primary btn-loading-animate',
    STOP: 'btn-danger',
    START_ALL: 'btn-outline-primary btn-loading-animate btn-danger btn-primary',
    SHOW: 'show mb-2',
  };

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Demo {
    constructor(element) {
      // The current countdown element
      this.element = element;
      const $element = $(element);
      this.path = $element.attr(Selector.START_ATTR) || Default.ID;
      this.startText = $element.attr(Selector.TXT_START_ATTR) || Default.TXT_START;
      this.stopText = $element.attr(Selector.TXT_STOP_ATTR) || Default.TXT_STOP;
      this.loadingText = $element.attr(Selector.TXT_LOADING_ATTR) || Default.TXT_LOADING;
      this.buttonVideo = $(Selector.VIDEO);
      this.buttonVideo.text(Text.VIDEO_START);
      this.buttonMedia = $(Selector.SCREEN);
      this.buttonMedia.text(Text.SCREEN_START);
      this.popup = $(Selector.POPUP);
      this.rtc = null;
      this.ws = null;
      this.WS_RETRY_TIMEOUT = 1000;

      this.qrElement = $(Selector.QRCODE);
      this.qrOptions = {
        width: 512,
        height: 512,
        foreground: $('.navbar').css('background-color'),
        correctLevel: 3,
      };

      $element.find('span').text(this.startText);

      this.initDemo();
    }

    // getters
    static get VERSION() {
      return VERSION;
    }

    static get key() {
      /* eslint-disable no-array-constructor */
      // Bug in uglyfier prevents [...Array(2).keys()].reduce()
      const uniqueKey = new Array(2, 0).reduce((acc) => `${acc}${Math.random().toString(36).substring(2, 15)}`, '');
      /* eslint-enable no-array-constructor */
      return uniqueKey;
    }

    // Get demo URI
    getURI(key) {
      /* eslint-disable no-restricted-globals, no-constant-condition */
      const host = location.hostname;
      const demoDebug = false ? '?debug=true' : '';
      /* eslint-enable no-restricted-globals, no-constant-condition */

      return new URL(`https://${host}${this.path}/${encodeURIComponent(key)}${demoDebug}`);
    }

    showQRCode(uri) {
      // Show QR code
      this.qrElement.empty()
        .qrcode($.extend({}, this.qrOptions, { text: uri }))
        .addClass(StyleClass.SHOW)
        .get(0).href = uri;
    }

    hideQRCode() {
      this.qrElement.removeClass(StyleClass.SHOW);
    }

    wsConnect() {
      try {
        this.ws = new WebSocket('ws://localhost:13337');

        this.ws.onopen = () => {
          console.log('WS open');
        };
        this.ws.onclose = () => {
          console.log('WS close');
          this.ws = null;
        };
        this.ws.onerror = () => {
          console.log('WS error');
          if (this.ws.readyState !== WebSocket.CLOSED) {
            this.ws.close();
          }
        };
      } catch (err) {
        // ignore
      }
    }

    initPopup() {
      // Get elements
      const $popup = this.popup;
      const $popupMessage = $popup.find('input');
      const $sendButton = $popup.find('button');

      // Send a popup message
      const sendPopup = () => {
        this.rtc.sendPopup($popupMessage.val());
        $popupMessage.val('');
      };

      // Set event handlers
      $popupMessage.on('keydown', (evt) => { if (evt.code === 'Enter') sendPopup(); });
      $sendButton.on('click', sendPopup);
    }

    initDemo() {
      // Initialize popup feature
      this.initPopup();

      const $element = $(this.element);
      const $controls = $(Selector.CONTROLS);

      const videoParent = $(Selector.VIDEO_CONTAINER);
      this.videoOverlay = new VideoOverlay({ parent: videoParent.get(0), embed: true });

      this.buttonVideo.on('click', () => {
        if (!this.videoOverlay.hidden()) {
          this.videoOverlay.hide();

          // Remove tracks from RTC steam
          if (this.rtc) this.rtc.removeTracks();

          // Stop video and update button text
          this.videoOverlay.stop();
          this.buttonVideo.text(Text.VIDEO_START);
        } else {
          this.buttonVideo.text(Text.VIDEO_STOP);
          navigator.mediaDevices.getUserMedia({
            video: true,
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
            },
          })
            .then((stream) => {
              this.videoOverlay.element.srcObject = stream;
              this.videoOverlay.mute();
              this.videoOverlay.play()
                .then(() => this.videoOverlay.show());

              // Add stream to RTC
              if (this.rtc) this.rtc.addStream(stream);
            })
            .catch((err) => {
              console.log(err);
              this.buttonVideo.attr('disabled', true)
                .text(Text.VIDEO_DENIED);
            });
        }
      });

      this.buttonMedia.on('click', () => {
        if (!this.videoOverlay.hidden()) {
          this.videoOverlay.hide();

          // Remove tracks from RTC steam
          if (this.rtc) this.rtc.removeTracks();

          // Stop video and update button text
          this.videoOverlay.stop();
          this.buttonMedia.text(Text.SCREEN_START);
        } else {
          this.buttonMedia.text(Text.SCREEN_STOP);
          navigator.mediaDevices.getDisplayMedia({
            video: {
              curstor: 'always',
            },
            audio: false,
          })
            .then((stream) => {
              this.videoOverlay.element.srcObject = stream;
              this.videoOverlay.mute();
              this.videoOverlay.play()
                .then(() => this.videoOverlay.show());

              // Add stream to RTC
              if (this.rtc) this.rtc.addStream(stream);

              // Remove tracks if user pressed browser 'stop sharing' button
              stream.getTracks().forEach((track) => {
                track.addEventListener('ended', () => {
                  if (this.rtc) this.rtc.removeTracks();
                  this.buttonMedia.text(Text.SCREEN_START);
                });
              });
            })
            .catch((err) => {
              console.log(err);
              this.buttonMedia.attr('disabled', true)
                .text(Text.SCREEN_DENIED);
            });
        }
      });

      // Connect to Websocket
      this.wsConnect();

      // Set starting classes
      $element.removeClass(StyleClass.START_ALL).addClass(StyleClass.START);

      $element.on('click', () => {
        if (this.rtc) this.rtc.close();
        if ($element.find('span').text() === this.loadingText) {
          this.rtc.close();
          $element.removeClass(StyleClass.START_ALL)
            .addClass(StyleClass.START)
            .find('span').text(this.startText);
          return;
        }
        if ($element.find('span').text() !== this.startText) return;

        $element.removeClass(StyleClass.START_ALL)
          .addClass(StyleClass.LOADING)
          .find('span').text(this.loadingText);

        const clientKey = Demo.key;
        this.rtc = new RTCCon('server', clientKey);

        this.rtc.onopen = () => {
          console.log('RTC open');
          $element.find('span').text(this.stopText);
          $element.removeClass(StyleClass.START_ALL).addClass(StyleClass.STOP);
          $controls.addClass(StyleClass.SHOW);

          this.hideQRCode();
        };

        this.rtc.onclose = () => {
          console.log('RTC closed');
          this.rtc = null;
          $element.find('span').text(this.startText);
          $element.removeClass(StyleClass.START_ALL).addClass(StyleClass.START);

          $controls.removeClass(StyleClass.SHOW);

          // Stop and hide video
          this.videoOverlay.hide();
          this.videoOverlay.stop();
          this.buttonVideo.text(Text.VIDEO_START);
          this.buttonMedia.text(Text.SCREEN_START);

          this.hideQRCode();
        };

        this.rtc.onmessage = (msg) => {
          if (this.ws && WebSocket.OPEN === this.ws.readyState) this.ws.send(msg);
        };

        this.rtc.open();

        // Create demo URI
        const u = this.getURI(clientKey);
        console.log(`RTC open - ${u.toString()}`);
        this.showQRCode(u.toString());
      });
    }

    static jQueryInterface() {
      return this.each(function jqEachDemo() {
        const $element = $(this);
        let data = $element.data(DATA_KEY);
        if (!data) {
          data = new Demo(this);
          $element.data(DATA_KEY, data);
        }
      });
    }
  }
  // END Class definition

  /**
   * ------------------------------------------------------------------------
   * Initialise by data attribute
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    const demosOnPage = $.makeArray($(Selector.START));

    /* eslint-disable no-plusplus */
    for (let i = demosOnPage.length; i--;) {
      const $demo = $(demosOnPage[i]);
      Demo.jQueryInterface.call($demo, $demo.data());
    }
  });

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */
  /* eslint-disable no-param-reassign */
  $.fn[NAME] = Demo.jQueryInterface;
  $.fn[NAME].Constructor = Demo;
  $.fn[NAME].noConflict = function DemoNoConflict() {
    $.fn[NAME] = JQUERY_NO_CONFLICT;
    return Demo.jQueryInterface;
  };
  /* eslint-enable no-param-reassign */

  return Demo;
})(jQuery);

export default exDemo;
