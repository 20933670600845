const StyleClass = {
  EMBEDDED: 'demo-video-embed',
  OVERLAY: 'demo-video-overlay',
  FULLSCREEN: 'demo-video-fullscreen',
  SHOW: 'show',
};

const Dataset = {
  CONTAINER: 'demoVideo',
};

const videoPoster = '/assets/img/play.png';
const silenceAudio = '/assets/audio/silence.mp3';

class VideoOverlay {
  constructor({ parent, style } = {}) {
    this.container = document.createElement('div');
    this.element = document.createElement('video');
    this.clickOffset = { x: 0, y: 0 };
    this.boundDragStop = this.dragVideoStop.bind(this);
    this.boundDragStart = this.dragVideoStart.bind(this);
    this.boundDrag = this.dragVideo.bind(this);
    switch (style && style.toLowerCase()) {
      case 'embed':
      case 'embedded':
        this.videoClass = StyleClass.EMBEDDED;
        break;

      case 'fullscreen':
        this.videoClass = StyleClass.FULLSCREEN;
        break;

      default:
        this.videoClass = StyleClass.OVERLAY;
        break;
    }
    this.parent = (parent || document.body);

    this.init();
  }

  init() {
    // Set container settings
    const resizableOptions = this.videoClass === StyleClass.EMBEDDED ? { handles: 's' } : { handles: 'all' };
    this.container.dataset[Dataset.CONTAINER] = true;
    this.container.classList.add(this.videoClass);
    if (this.videoClass === StyleClass.OVERLAY) Object.assign(this.parent.style, { position: 'relative', overflow: 'hidden' });
    if (this.videoClass !== StyleClass.FULLSCREEN) $(this.container).resizable(resizableOptions);
    // Remove relative positioning on element, we've already got aboslute from CSS
    this.container.style.removeProperty('position');

    // Set video settings
    this.element.poster = videoPoster;
    this.element.setAttribute('autoplay', true);
    this.element.setAttribute('playsinline', true);
    this.container.append(this.element);
    this.parent.append(this.container);

    // Unlock audio for iOS
    const sound = new Audio(silenceAudio);
    const unlockAudio = () => {
      sound.play()
        .then(() => {
          sound.pause();
          sound.currentTime = 0;
          document.body.removeEventListener('click', unlockAudio);
          document.body.removeEventListener('touchstart', unlockAudio);
        })
        .catch((error) => console.log('AudioUnlock error', error));
    };
    document.body.addEventListener('click', unlockAudio);
    document.body.addEventListener('touchstart', unlockAudio);

    // Play video on click (iOS autoplay workaround)
    this.element.addEventListener('click', this.play.bind(this), true);
    this.element.addEventListener('touchstart', this.play.bind(this), true);

    // Fullscreen request
    let doubleClickCounter = 0;
    const doubleClickTest = (evt) => {
      setTimeout(() => { doubleClickCounter = 0; }, 500);
      doubleClickCounter += 1;
      if (doubleClickCounter >= 2) this.requestFullscreen();
      evt.preventDefault();
    };
    this.element.addEventListener('touchstart', doubleClickTest);
    this.element.addEventListener('dblclick', this.requestFullscreen.bind(this));

    // Handlers for draggable overlay
    if (this.videoClass === StyleClass.OVERLAY) {
      this.element.addEventListener('mouseup', this.boundDragStop, true);
      this.element.addEventListener('mouseout', this.boundDragStop, true);
      this.element.addEventListener('mousedown', this.boundDragStart, true);

      this.element.addEventListener('touchend', this.boundDragStop, true);
      this.element.addEventListener('touchstart', this.boundDragStart, true);
    }
  }

  // Drag controls for video overlay
  dragVideoStart(evt) {
    const br = this.container.getBoundingClientRect();
    const event = (evt.touches && evt.touches[0])
      || (evt.originalEvent && evt.originalEvent.touches[0])
      || evt;
    this.clickOffset.x = (window.scrollX + br.left) - event.pageX;
    this.clickOffset.y = (window.scrollY + br.top) - event.pageY;
    this.element.addEventListener('mousemove', this.boundDrag, true);
    this.element.addEventListener('touchmove', this.boundDrag, true);
    evt.preventDefault();
  }

  dragVideo(evt) {
    const event = (evt.touches && evt.touches[0])
      || (evt.originalEvent && evt.originalEvent.touches[0])
      || evt;
    this.container.style.left = `${event.pageX + this.clickOffset.x}px`;
    this.container.style.top = `${event.pageY + this.clickOffset.y}px`;
    evt.stopPropagation();
  }

  dragVideoStop() {
    this.element.removeEventListener('mousemove', this.boundDrag, true);
    this.element.removeEventListener('touchmove', this.boundDrag, true);
  }

  get srcObject() {
    return this.element.srcObject;
  }

  set srcObject(stream) {
    if (stream) {
      // Stop the video
      stream.addEventListener('removetrack', ({ track }) => {
        console.log(`${track.kind} track was removed.`);
        this.stop();
        this.hide();
      });
    }
    this.element.srcObject = stream;
  }

  show() {
    this.container.classList.add(StyleClass.SHOW);
    return true;
  }

  hide() {
    this.container.classList.remove(StyleClass.SHOW);
    return true;
  }

  hidden() {
    return !this.container.classList.contains(StyleClass.SHOW);
  }

  mirror() {
    this.element.style.transform = this.element.style.transform ? '' : 'scaleX(-1)';
  }

  mute() {
    this.element.muted = true;
  }

  unmute() {
    this.element.muted = false;
  }

  toggle() {
    return this.hidden() ? this.show() : !this.hide();
  }

  requestFullscreen() {
    const { element } = this;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitEnterFullScreen) {
      element.webkitEnterFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  play() {
    return this.element.play()
      .then(() => {
        const ret = Promise.resolve();
        // Return immediately if no srcObject
        if (!this.srcObject) return ret;

        // Enable all tracks and setup 'ended' event handler
        this.srcObject.getTracks().forEach((track) => {
          console.log('Enabling track');
          /* eslint-disable no-param-reassign */
          track.enabled = true;

          // Hide video when track has ended
          track.addEventListener('ended', () => {
            console.log('Video ended');
            this.hide();
          });
          /* eslint-enable no-param-reassign */
        });
        return ret;
      })
      .catch((err) => {
        console.error('Track error', err);
        throw err;
      });
  }

  pause() {
    return this.element.pause();
  }

  stop() {
    if (!this.srcObject) return;

    this.srcObject.getTracks().forEach((track) => {
      /* eslint-disable no-param-reassign */
      track.enabled = false;
      /* eslint-enable no-param-reassign */
      track.stop();
    });
    this.srcObject = null;
    console.log('Video overlay stopped');
  }
}

export default VideoOverlay;
