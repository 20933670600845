class Signaling {
  constructor(role, clientId) {
    this.signalURI = '/signaling.php';
    this.lastPoll = 0;
    this.pollInt = null;
    this.pollTimeout = null;
    this.role = role;
    this.clientId = clientId;
  }

  poll() {
    const formData = new FormData();
    formData.append('method', 'poll');
    formData.append('role', (this.role === 'caller') ? 'callee' : 'caller');
    formData.append('cid', this.clientId);
    formData.append('lastId', this.lastPoll);

    fetch(this.signalURI, {
      method: 'POST',
      credentials: 'same-origin',
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        for (let i = 0; json && i < json.length; i += 1) {
          if (this.callback) this.callback(JSON.parse(json[i].data));
        }
      })
      .catch((error) => {
        console.error(error);
        this.stopPoll();
      });
  }

  startPoll(interval = 1000, timeout = 30000) {
    this.stopPoll();
    this.pollInt = window.setInterval(this.poll.bind(this), interval);
    this.pollTimeout = window.setTimeout(this.stopPoll.bind(this), timeout);
  }

  stopPoll() {
    if (this.pollInt) window.clearInterval(this.pollInt);
    if (this.pollTimeout) window.clearTimeout(this.pollTimeout);
    this.pollInt = null;
    this.pollTimeout = null;
  }

  push(data) {
    const formData = new FormData();
    formData.append('method', 'push');
    formData.append('type', 'signaling');
    formData.append('role', this.role);
    formData.append('cid', this.clientId);
    formData.append('data', JSON.stringify(data.toJSON()));

    fetch(this.signalURI, {
      method: 'POST',
      credentials: 'same-origin',
      body: formData,
    })
      .then((response) => response.text())
      .then((txt) => console.log(txt))
      .catch((error) => console.log(error));
  }
}

export default Signaling;
