import Signaling from './signaling';

class RTCCon {
  constructor(mode, clientId, forceRelay = true) {
    if (typeof clientId === 'string') {
      this.clientId = clientId;
    }

    switch (mode.toLowerCase()) {
      case 'server':
      case 'caller':
        this.role = 'caller';
        break;

      case 'client':
      case 'callee':
        this.role = 'callee';
        break;

      default:
        console.error(`Invalid mode ${mode}`);
        break;
    }

    this.signal = new Signaling(this.role, this.clientId);
    this.signal.callback = this.onsignal.bind(this);
    this.stopPollTimeout = null;
    this.iceTransportPolicy = !forceRelay ? 'all' : 'relay';
  }

  open() {
    // First get the ICE servers
    fetch('/iceservers.php', { credentials: 'same-origin' })
      .then((iceServers) => iceServers.json())
      .then((iceServersJSON) => {
        const iceConfig = {
          iceServers: iceServersJSON,
          iceTransportPolicy: this.iceTransportPolicy,
        };

        // Create the RTC peer connection
        this.peerCon = new RTCPeerConnection(iceConfig);

        // Push all our candidates
        this.peerCon.onicecandidate = (e) => {
          if (e && e.candidate) this.signal.push(e.candidate);
        };

        // Check if we are the callee or the caller
        if (this.role === 'callee') {
          // If we are the callee, we simply wait for a datachannel
          this.peerCon.ondatachannel = (e) => {
            this.dataChan = e.channel;
            this.dataChan.onmessage = this.intOnmessage.bind(this);
            this.dataChan.onopen = this.intOnopen.bind(this);
            this.dataChan.onclose = this.intOnclose.bind(this);
          };
        } else {
          // If we are the caller, we have to handle ICE
          this.peerCon.onnegotiationneeded = () => {
            this.peerCon.createOffer()
              .then((offer) => {
                console.log('Set local description offer');
                return this.peerCon.setLocalDescription(offer);
              })
              .then(() => {
                console.log('Signaling offer');
                this.signal.push(this.peerCon.localDescription);
              })
              .catch((error) => console.log('Error creating negotiation offer', error));
          };

          // Create the datachannel and trigger ICE
          this.dataChan = this.peerCon.createDataChannel('DataChannel', {
            protocol: 'custom',
          });

          // Set the callbacks
          this.dataChan.onmessage = this.intOnmessage.bind(this);
          this.dataChan.onopen = this.intOnopen.bind(this);
          this.dataChan.onclose = this.intOnclose.bind(this);
        }

        // Start the polling process
        this.startPoll(1000);
      })
      .catch((error) => console.log('Error opening RTC connection', error));
  }

  sendPopup(val) {
    this.send({ popup: val });
  }

  sendMeta(val) {
    if (typeof val === 'string' && this.dataChan && this.dataChan.readyState === 'open') {
      this.dataChan.send(JSON.stringify({ meta: val }));
    }
  }

  addStream(stream) {
    stream.getTracks().forEach((t) => this.peerCon.addTrack(t, stream));
    this.startPoll();
    this.sendMeta('poll');
  }

  removeTracks() {
    this.peerCon.getTransceivers().forEach(({ sender }) => {
      /* eslint-disable no-param-reassign */
      if (sender.track) sender.track.enabled = false;
      /* eslint-enable no-param-reassign */
      this.peerCon.removeTrack(sender);
    });
    this.sendMeta('poll');
  }

  close() {
    if (this.peerCon) {
      console.log('Closing RTCPeerConnection');
      this.sendMeta('close');
      this.peerCon.close();
    }
    this.peerCon = null;
    this.dataChan = null;
    this.stopPoll();
  }

  intOnmessage(e) {
    let o;
    try {
      o = JSON.parse(e.data);
    } catch (error) {
      console.error('Got a syntax error while parsing JSON');
    }
    if (o && typeof o.meta === 'string') {
      console.log(`Received ${o.meta} meta message`);

      const metaData = o.meta.toLowerCase();
      switch (metaData) {
        case 'close':
          this.close();
          break;

        case 'poll':
          this.startPoll();
          break;

        default:
          if (typeof this.onmeta === 'function') this.onmeta(metaData);
          break;
      }
    } else if (typeof this.onmessage === 'function' && o && o.data) {
      this.onmessage(o.data);
    }
  }

  intOnopen() {
    this.stopPoll(6000);
    if (typeof this.onopen === 'function') {
      this.onopen();
    }
  }

  intOnclose() {
    this.close();
    this.stopPoll();
    if (typeof this.onclose === 'function') {
      this.onclose();
    }
  }

  startPoll(interval = 1000) {
    clearTimeout(this.stopPollTimeout);
    this.signal.startPoll(interval);
  }

  stopPoll(timeout = 0) {
    clearTimeout(this.stopPollTimeout);
    this.stopPollTimeout = setTimeout(this.signal.stopPoll.bind(this.signal), timeout);
  }

  send(data) {
    try {
      if (this.dataChan && this.dataChan.readyState === 'open') this.dataChan.send(JSON.stringify({ data }));
    } catch (err) {
      console.error('RTC send error', err);
    }
  }

  onsignal(obj) {
    try {
      if (obj.sdp !== undefined) {
        if (obj.type === 'offer') {
          console.log('Offer');
          const o = new RTCSessionDescription(obj);
          this.peerCon.setRemoteDescription(o)
            .then(() => this.peerCon.createAnswer())
            .then((answer) => this.peerCon.setLocalDescription(answer))
            .then(() => {
              console.log('Signaling answer');
              this.signal.push(this.peerCon.localDescription);
            });
        } else if (obj.type === 'answer') {
          console.log('Answer');
          const o = new RTCSessionDescription(obj);
          this.peerCon.setRemoteDescription(o);
        } else {
          console.error('Unsupported SDP object');
          console.log(obj);
        }
      } else if (obj.candidate !== undefined) {
        console.log('Candidate');
        const o = new RTCIceCandidate(obj);
        this.peerCon.addIceCandidate(o);
      } else {
        console.error('Unknown signaling object');
        console.log(obj);
      }
    } catch (err) {
      console.error('Error while processing signaling object');
      console.log(err);
      console.log(obj);
    }
  }
}

export default RTCCon;
